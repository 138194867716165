.cards {
  transition: all 350ms;
}

.cards:hover {
  background-color: #1a3660;
}

.btns > .btn {
  transition: all 350ms;
}

.btns:hover > .btn {
  background-color: #4dbbbd;
  color: white;
}

.filter {
  filter: invert(61%) sepia(45%) saturate(481%) hue-rotate(132deg)
    brightness(98%) contrast(120%);
}
