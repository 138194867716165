.bg-image {
  background: #333;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-full {
  height: 90vh;
  width: 100%;
}

.bg-main {
  background-image: linear-gradient(to bottom, rgba(16, 19, 33, 0.52), #05060b),
    url("../assets/hero.webp");
  background-position: top;
}

.bg-half {
  min-height: 70vh;
  width: 100%;
  background-position: center;
}
